import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CurrencyConverter from './CurrencyConverter';
import Careerlets from './Careerlets';
import Header from './Header';
import Footer from './Footer';
import Blog from './Blog'; // Import Blog component

const App = () => {
  return (
    <Router>
      <div style={styles.container}>
        <Header />
        <div style={styles.content}>
          <Routes>
            <Route path="/" element={
              <>
                <CurrencyConverter />
                <Careerlets />
              </>
            } />
            <Route path="/blog" element={<Blog />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: '#dde2dd',
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '20px',
    padding: '40px 20px',
    width: '100%',
    flexWrap: 'wrap',
    flexGrow: 1,
    boxSizing: 'border-box',
  },
};

export default App;
