import React, { useState, useEffect } from 'react';

const currencyData = [
  { code: 'UGX', name: 'Ugandan Shilling', rate: 3420, flag: '🇺🇬' },
  { code: 'TZS', name: 'Tanzanian Shilling', rate: 2450, flag: '🇹🇿' },
  { code: 'RWF', name: 'Rwandan Franc', rate: 1100, flag: '🇷🇼' },
  { code: 'ZMK', name: 'Zambian Kwacha', rate: 23, flag: '🇿🇲' }, // Added Zambian Kwacha
  { code: 'BIF', name: 'Burundi Franc', rate: 2500, flag: '🇧🇮' }, // Added Burundi Franc
];

const CurrencyConverter = () => {
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState('');
  const [transactionFee, setTransactionFee] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (amount && !selectedCurrency) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
  
    if (amount && selectedCurrency) {
      const converted = (parseFloat(amount) * selectedCurrency.rate).toFixed(2);
  
      // Calculate the transaction fee as 1% of the converted amount
      const fee = (parseFloat(converted) * 0.02).toFixed(2);
  
      // Deduct the transaction fee from the converted amount
      const receiveAmount = (parseFloat(converted) - parseFloat(fee)).toFixed(2);
  
      setTransactionFee(fee);
      setConvertedAmount(receiveAmount);
    } else {
      setConvertedAmount('');
      setTransactionFee('');
    }
  }, [amount, selectedCurrency]);

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Convert Amount to be Sent.</h2>
      <div style={styles.form}>
       
        <div style={styles.section}>
          <label htmlFor="usd-amount" style={styles.label}>Amount in USD (min.$10  max.$1,000)</label>
          <input
            id="usd-amount"
            type="number"
            placeholder="Enter amount in USD"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            style={styles.input}
          />
        </div>
         {/* Warning message */}
         <div style={styles.warning}>
          <span style={styles.warningIcon}>⚠️</span> Make sure you first REFRESH the page to get the current rates.
        </div>
        <div style={styles.section}>
          <label htmlFor="local-currency" style={styles.label}>Select Local Currency</label>
          <select
            id="local-currency"
            onChange={(e) => setSelectedCurrency(currencyData.find(c => c.code === e.target.value))}
            style={styles.select}
          >
            <option value="">Select local currency</option>
            {currencyData.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.flag} {currency.name}
              </option>
            ))}
          </select>
        </div>
        {showPrompt && (
          <div style={styles.prompt}>
            Please select a local currency to see the converted amount.
          </div>
        )}
        {selectedCurrency && convertedAmount && (
          <div style={styles.result}>
            <div style={styles.summary}>
              <p><strong>Amount in USD:</strong> {amount} USD</p>
              <div style={styles.exchangeRate}>
              <p><strong>Exch Rate:</strong>  1 USD = <span style={styles.highlight}> {selectedCurrency.rate} {selectedCurrency.code}</span></p>
            </div>
              <div style={styles.transactionFee}>
              <p><strong>Paypal Fee:</strong> <span style={styles.highlight}>{transactionFee} {selectedCurrency.code}</span></p>
            </div>
            <div style={styles.amountReceived}>
              <p><strong>Total Amount to be Received:</strong> <span style={styles.highlightGreen}>{convertedAmount} {selectedCurrency.code}</span></p>
           </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    flex: '1',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '450px',
    margin: '0 auto',
    MarginLeft: '24px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '24px',
    color: '#048243',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  warning: {
    color: '#b90b08',
    fontWeight: 'bold',
    fontSize: '14px',
    //marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    marginRight: '4px',
  },
  section: {
    marginBottom: '12px',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '4px',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '8px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
  },
  select: {
    width: '100%',
    padding: '8px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
  },
  prompt: {
    backgroundColor: '#e0f2fe',
    color: '#0369a1',
    padding: '12px',
    borderRadius: '8px',
    fontSize: '14px',
    marginTop: '8px',
  },
  result: {
    padding: '16px',
    backgroundColor: '#f1f5f9',
    borderRadius: '8px',
  },
  summary: {
    marginBottom: '12px',
    fontSize: '16px',
  },
  highlight: {
    color: '#10b981',
    fontWeight: '200',
  },
  highlightGreen: {
    color: '#16a34a',
    fontWeight: 'bold',
  },
  exchangeRate: {
    fontSize: '16px',
    fontWeight: '100',
  },
  transactionFee: {
    fontSize: '16px',
    fontWeight: '100',
  },
  amountReceived: {
    marginTop: '12px',
    fontSize: '16px',
  }
};

export default CurrencyConverter;

