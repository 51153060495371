import React from 'react';
import { FaWhatsapp, FaEnvelope, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.contactUs}>
        <div style={styles.contactText}>Contact us</div>
        <div style={styles.iconContainer}>
          <a href="https://wa.me/+254701632591" style={styles.icon} target="_blank" rel="noopener noreferrer">
            <FaWhatsapp />
          </a>
          <a href="mailto:careerlets@yahoo.com" style={styles.icon}>
            <FaEnvelope />
          </a>
          <a href="https://www.instagram.com/careerlets" style={styles.icon} target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
      </div>
      <div style={styles.copyright}>
        Copyright © 2024. Careerlets
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column', // Stack elements vertically
    alignItems: 'center',
    background: 'linear-gradient(90deg, #000000, #434343)', // Gradient background
    color: '#ffffff',
    boxSizing: 'border-box',
  },
  contactUs: {
    display: 'flex',
    flexDirection: 'column', // Stack "Contact Us" and icons vertically
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px', // Add space between contact section and copyright
  },
  contactText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  iconContainer: {
    display: 'flex',
    gap: '10px', // Space between icons
  },
  icon: {
    color: '#ffffff', // Icon color for contrast
    fontSize: '20px',
    textDecoration: 'none',
  },
  copyright: {
    fontSize: '14px',
    textAlign: 'center',
  },
};

export default Footer;
