import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faHome } from '@fortawesome/free-solid-svg-icons'; // Import the home icon
import myLogo from './logo.png';
import './Header.css'; // Import the CSS file

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <img src={myLogo} alt="logo" className="logo" />
      <div className="taglineContainer">
        <div className="tagline">
          <p className="movingText">
            Trusted & Safe Money Transfers from PayPal to Mobile Money
          </p>
        </div>
      </div>

      {/* Hamburger Menu Icon */}
      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      {/* Navigation Links */}
      <nav className={`nav ${isMobileMenuOpen ? 'mobileOpen' : ''}`}>
        <Link to="/" className="navLink">
          <FontAwesomeIcon icon={faHome} size="lg" /> {/* Home icon */}
        </Link>
        <Link to="/blog" className="navLink blogLink">Blog</Link> {/* Smaller Blog */}
      </nav>
    </header>
  );
};

export default Header;
