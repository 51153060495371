import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { CountryDropdown } from 'react-country-region-selector';
import paypal from './paypal.png';
import mtn from './mtn.png';
import airtel from './airtel.png';
import mpesa from './mpesa.png';
import vodacom from './vodacom.png';
import tigo from './tigo.png';

const Careerlets = () => {
  const [formData, setFormData] = useState({
    paypalName: '',
    paypalEmail: '',
    receiverCountry: '',
    mobileMoneyNetwork: '',
    receiverFullName: '',
    mobileMoneyNumber: '',
    receiverEmail: '',
    amount: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation rules
    const lettersOnly = /^[A-Za-z\s]*$/;
    const numbersOnly = /^\+\d*$/;

    // Validate input based on the field name
    if (name === "receiverFullName" || name === "mobileMoneyNetwork") {
      if (!lettersOnly.test(value)) {
        return; // Prevents updating state if input is not valid
      }
    }

    if (name === "mobileMoneyNumber") {
      if (!numbersOnly.test(value)) {
        return; // Prevents updating state if input is not valid
      }
    }

    // Update state if validation passes
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCountryChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      receiverCountry: val,
    }));
  };

  const isWithinOperatingHours = () => {
    const now = new Date();
    const hours = now.getUTCHours() + 3; // EAT is UTC+3
    return hours >= 8 && hours < 23;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!isWithinOperatingHours()) {
      Swal.fire({
        title: "Error",
        text: "Sorry, transfers are made between 8:00am and 11:00pm East African Time.",
        icon: "error",
      });
      setIsSubmitting(false);
      return;
    }

    const allFieldsFilled = Object.values(formData).every((field) => field !== '');
    if (!allFieldsFilled) {
      Swal.fire({
        title: "Error",
        text: "Please fill in all fields before submitting.",
        icon: "error",
      });
      setIsSubmitting(false);
      return;
    }

    // Validate the amount
    const amount = parseFloat(formData.amount);
    if (isNaN(amount) || amount < 10 || amount > 500) {
      Swal.fire({
        title: "Error",
        text: "The amount must be between $10 and $500.",
        icon: "error",
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const submissionData = {
        ...formData,
        access_key: process.env.REACT_APP_API_KEY,
      };

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(submissionData),
      });

      const result = await res.json();

      if (result.success) {
        Swal.fire({
          title: "Your Invoice has been sent successfully!",
          html: "<strong>You will be notified by Email as soon as your funds are ready!</strong>",
          icon: "success",
        });

        setFormData({
          paypalName: '',
          paypalEmail: '',
          receiverCountry: '',
          mobileMoneyNetwork: '',
          receiverFullName: '',
          mobileMoneyNumber: '',
          receiverEmail: '',
          amount: '',
        }); // Reset form
        window.location.reload()
      } else {
        throw new Error(result.message || "Submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Oops...",
        text: "An error occurred. Please try again later.",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formWrapper}>
        <form onSubmit={onSubmit} style={styles.form}>
          <div style={styles.logoContainer}>
            <div style={styles.paypalLogoContainer}>
              <img src={paypal} alt="PayPal" style={styles.paypalLogo} />
            </div>
            <div style={styles.otherLogosContainer}>
              <img src={mtn} alt="MTN" style={styles.logo} />
              <img src={airtel} alt="Airtel" style={styles.logo} />
              <img src={mpesa} alt="M-Pesa" style={styles.logo} />
              <img src={vodacom} alt="Vodacom" style={styles.logo} />
              <img src={tigo} alt="Tigo" style={styles.logo} />
            </div>
          </div>

          <h3 style={styles.header}>Sender's Information</h3>

          <div style={styles.formGroup}>
            <label htmlFor="paypalName" style={styles.label}>
              Sender's PayPal Name
            </label>
            <input
              type="text"
              id="paypalName"
              name="paypalName"
              value={formData.paypalName}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="paypalEmail" style={styles.label}>
              Sender's PayPal Email
            </label>
            <input
              type="email"
              id="paypalEmail"
              name="paypalEmail"
              value={formData.paypalEmail}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="amount" style={styles.label}>
              Amount to be Sent ($)
            </label>
            <input
              type="text"
              id="amount"
              name="amount"
              value={formData.amount}
              placeholder="min. $10    max. $500"
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <h3 style={styles.header}>Receiver's Information</h3>

          <div style={styles.formGroup}>
            <label htmlFor="receiverCountry" style={styles.label}>
              Receiver's Country
            </label>
            <CountryDropdown
              value={formData.receiverCountry}
              onChange={handleCountryChange}
              priorityOptions={['UG', 'TZ', 'RW', 'BI', 'ZM']}
              whitelist={['UG', 'TZ', 'RW', 'BI', 'ZM']}
              style={styles.input}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="mobileMoneyNetwork" style={styles.label}>
              Mobile Money Network e.g MTN, AIRTEL, etc
            </label>
            <input
              type="text"
              id="mobileMoneyNetwork"
              name="mobileMoneyNetwork"
              value={formData.mobileMoneyNetwork}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="receiverFullName" style={styles.label}>
              Receiver's Full Name on Mobile Money
            </label>
            <input
              type="text"
              id="receiverFullName"
              name="receiverFullName"
              value={formData.receiverFullName}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="mobileMoneyNumber" style={styles.label}>
              Mobile Money Number <span style={styles.redText}>(MUST include code: +256, +255, etc.)</span>
            </label>
            <input
              type="tel"
              id="mobileMoneyNumber"
              name="mobileMoneyNumber"
              value={formData.mobileMoneyNumber}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="receiverEmail" style={styles.label}>
              Receiver's Email Address
            </label>
            <input
              type="email"
              id="receiverEmail"
              name="receiverEmail"
              value={formData.receiverEmail}
              onChange={handleChange}
              required
              style={styles.input}
            />
          </div>

          <button type="submit" style={styles.button} disabled={isSubmitting}>
            {isSubmitting ? 'Processing...' : 'Submit Invoice'}
          </button>
        </form>
        <p style={styles.warning}>
          Warning: Transfers are made between 8:00am and 11:00pm EAT.
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    flex: '1',
    margin: '0 auto',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '500px',
  },
  formWrapper: {
    width: '100%',
    margin: '0 auto',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  paypalLogoContainer: {
    marginBottom: '0',
  },
  paypalLogo: {
    height: '80px',
    width: 'auto',
    maxWidth: '120px',
  },
  otherLogosContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0',
  
  },
  logo: {
    height: '50px',
    width: '50px',
    maxWidth: '100px',
    flex: '1 1 20%', 
  },
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  formGroup: {
    marginBottom: '10px',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '8px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
  },
  button: {
    marginTop: '20px',
    backgroundColor: 'green',
    color: 'white',
    padding: '12px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
  },
  warning: {
    textAlign: 'center',
    color: '#999',
    fontSize: '14px',
    marginTop: '12px',
  },
  redText: {
    color: 'red',
    fontWeight: 'bold',
  },
};

export default Careerlets;

